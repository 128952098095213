import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API } from '@quantum-theme/core/_config/api/api';
import { Observable } from 'rxjs';
import { ResponseModel } from '@_core/models/response';
import { QueryUtilsModel } from '@_core/models/logic-custom-query/query-utils.model';
import { QueryCriteria } from '@_core/models/logic-custom-query/query-criteria.model';
import { SystemCodeMap } from '@quantum-theme/core/_config/system-code-map';
import { baseURI } from '@environments/environment';

const corporateAPI = API.corporate;
const formManagementAPI = API.formManagement;
const baseUrl = API.baseUrl;

@Injectable()
export class CorporateService {
  constructor(
    private http: HttpClient,
  ) { }

  // 拿出登入者部門與登入者部門之子部門
  queryCorporate(query: string, topOrgId?: number, selected?: string): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 99
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };

    queryUtils.queryCriterias = [
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(topOrgId ? [new QueryCriteria('top_org_id', topOrgId.toString(), '=', 'and', true)] : []),
      ...([new QueryCriteria('status', '_SYS_A9_1', 'like', 'and')]),
      ...([new QueryCriteria('noQueryParent', "1", '=')])
    ];

    if (selected) {
      queryUtils['params'] = {selectedOrg: selected};
    }
    return this.http.post<ResponseModel>(corporateAPI + '/list/table', queryUtils);
  }

  // 拿出全部的組織架構
  queryAllCorporate(query: string, topOrgId?: number): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 99
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };

    queryUtils.queryCriterias = [
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(topOrgId ? [new QueryCriteria('top_org_id', topOrgId.toString(), '=', 'and', true)] : [])
    ];

    return this.http.post<ResponseModel>(corporateAPI + '/allList', queryUtils);
  }

  queryCompany(query: string, topOrgId?: number): Observable<ResponseModel> {
    const { corporateStructureType } = SystemCodeMap;
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 99
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };

    queryUtils.queryCriterias = [
      new QueryCriteria('org_type', corporateStructureType.company, '=', 'and'),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(topOrgId ? [new QueryCriteria('top_org_id', topOrgId.toString(), '=', 'and', true)] : [])
    ];

    return this.http.post<ResponseModel>(corporateAPI + '/list/table', queryUtils);
  }

  queryDepartment(query: string, topOrgId?: number): Observable<ResponseModel> {
    const { corporateStructureType } = SystemCodeMap;
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 99
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };

    queryUtils.queryCriterias = [
      new QueryCriteria('org_type', corporateStructureType.department, '=', 'and'),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(topOrgId ? [new QueryCriteria('parent_id', topOrgId.toString(), 'in', 'and', true)] : []),
    ];

    return this.http.post<ResponseModel>(corporateAPI + '/list/table', queryUtils);
  }

  queryDoctorDepartment(query: string, topOrgId?: number): Observable<ResponseModel> {
    const { corporateStructureType } = SystemCodeMap;
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 99
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };

    queryUtils.queryCriterias = [
      new QueryCriteria('org_type', corporateStructureType.department, '=', 'and'),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(topOrgId ? [new QueryCriteria('parent_id', topOrgId.toString(), 'in', 'and', true)] : []),
      ...[new QueryCriteria('id', '3', 'in', 'and', true)],
    ];

    return this.http.post<ResponseModel>(corporateAPI + '/list/table', queryUtils);
  }

  queryBusinessLineDashboard(query: string, topOrgId?: number): Observable<ResponseModel> {
    const { corporateStructureType } = SystemCodeMap;
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 99
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };

    queryUtils.queryCriterias = [
      new QueryCriteria('org_type', corporateStructureType.department, '=', 'and'),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(topOrgId ? [new QueryCriteria('parent_id', topOrgId.toString(), 'in', 'and', true)] : []),
    ];

    return this.http.post<ResponseModel>(corporateAPI + '/list/table/dashboard', queryUtils);
  }
  queryBusinessLineDashboardEHS(query: string, topOrgId?: number, time?): Observable<ResponseModel> {
    const { corporateStructureType } = SystemCodeMap;
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 99
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };

    queryUtils.queryCriterias = [
      new QueryCriteria('org_type', corporateStructureType.department, '=', 'and'),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(topOrgId ? [new QueryCriteria('parent_id', topOrgId.toString(), 'in', 'and', true)] : []),
    ];
    if (time) {
      queryUtils['params'] = {searchTime: new Date(time).toISOString()};
    }

    return this.http.post<ResponseModel>(corporateAPI + '/list/table/history/dashboard', queryUtils);
  }

  queryBusinessLineTrainingNew(query: string, topOrgId?: number): Observable<ResponseModel> {
    const { corporateStructureType } = SystemCodeMap;
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 99
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };

    queryUtils.queryCriterias = [
      new QueryCriteria('org_type', corporateStructureType.department, '=', 'and'),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(topOrgId ? [new QueryCriteria('parent_id', topOrgId.toString(), 'in', 'and', true)] : []),
    ];

    return this.http.post<ResponseModel>(corporateAPI + '/list/table/tran/dashboard', queryUtils);
  }

  queryGroup(query: string, topOrgId?: number, checkType?: any): Observable<ResponseModel> {
    const { corporateStructureType } = SystemCodeMap;
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 99
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };
    

    queryUtils.queryCriterias = [
      new QueryCriteria('org_type', corporateStructureType.group, '=', 'and'),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(topOrgId ? [new QueryCriteria('top_org_id', topOrgId.toString(), '=', 'and', true)] : [])
    ];
    if (checkType) {
      if (checkType == '_SYS_BL_1' || checkType == '_SYS_BL_10' || checkType == '_SYS_BL_9' || checkType == '_SYS_BL_8') {
        queryUtils.queryCriterias.push({
          condition: "in",
          connection: "and",
          isValueADigital: false,
          key: "business_line_id",
          value: "5"
        })
      } else if (checkType == '_SYS_BL_12') {
        queryUtils.queryCriterias.push({
          condition: "in",
          connection: "and",
          isValueADigital: false,
          key: "business_line_id",
          value: "3"
        })
      } else if (checkType == '_SYS_BL_13') {
        queryUtils.queryCriterias.push({
          condition: "in",
          connection: "and",
          isValueADigital: false,
          key: "business_line_id",
          value: "4"
        })
      } else if (checkType == '_SYS_BL_11') {
        queryUtils.queryCriterias.push({
          condition: "in",
          connection: "and",
          isValueADigital: false,
          key: "business_line_id",
          value: "3,4,5"
        })
      }
    }

    return this.http.post<ResponseModel>(corporateAPI + '/list/table', queryUtils);
  }

  queryMedicalProject(query: string, topOrgId?: number): Observable<ResponseModel> {
    const { corporateStructureType } = SystemCodeMap;
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 99
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };

    queryUtils.queryCriterias = [
      new QueryCriteria('org_type', corporateStructureType.group, '=', 'and'),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(topOrgId ? [new QueryCriteria('top_org_id', topOrgId.toString(), '=', 'and', true)] : []),
      new QueryCriteria('business_line_name', '医疗业', 'like', 'and'),
    ];

    return this.http.post<ResponseModel>(corporateAPI + '/list/table', queryUtils);
  }

  queryBSProject(query: string, bsId?: number): Observable<ResponseModel> {
    const { corporateStructureType } = SystemCodeMap;
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 99
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };

    queryUtils.queryCriterias = [
      new QueryCriteria('org_type', corporateStructureType.group, '='),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(bsId ? [new QueryCriteria('business_line_id', bsId.toString(), '=')] : [])
    ];

    return this.http.post<ResponseModel>(corporateAPI + '/list/table', queryUtils);
  }

  // 自己权限下的项目
  queryOwnProjects(query: string, topOrgId?: number): Observable<ResponseModel> {
    const { corporateStructureType } = SystemCodeMap;
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 99
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };

    queryUtils.queryCriterias = [
      new QueryCriteria('org_type', corporateStructureType.group, '=', 'and'),
      ...(query ? [new QueryCriteria('short_name', query, 'like', 'and')] : []),
      ...(topOrgId ? [new QueryCriteria('top_org_id', topOrgId.toString(), '=', 'and', true)] : [])
    ];

    return this.http.post<ResponseModel>(corporateAPI + '/list/table/self', queryUtils);
  }

  queryGroupAll(query: string, topOrgId?: number): Observable<ResponseModel> {
    const { corporateStructureType } = SystemCodeMap;
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 99
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };

    queryUtils.queryCriterias = [
      new QueryCriteria('org_type', corporateStructureType.group, '=', 'and'),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(topOrgId ? [new QueryCriteria('top_org_id', topOrgId.toString(), '=', 'and', true)] : [])
    ];

    return this.http.post<ResponseModel>(corporateAPI + '/list/table/all', queryUtils);
  }

  // 检查类型
  // getCheckTypeAndTable(query?: string, inspectionType?: string, selectedOrgId?: string): Observable<ResponseModel> {
  getCheckTypeAndTable(tree_org_id): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = {
      'queryCriterias': [
        {
          'connection': 'and',
          'key': 'org_id',
          'condition': 'in',
          'value': String(tree_org_id),
          'isValueADigital': true,
        }
      ]
    };
    return this.http.post<ResponseModel>(formManagementAPI + '/havingPreview/list/tree', queryUtils);
  }

  getPerformanceList(): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(baseUrl + '/dashboardMonthly/title/list', {});
  }

  // 项目
  queryGroups(query: string, topOrgId?: string, inRanking?, business_line_id?): Observable<ResponseModel> {
    const { corporateStructureType } = SystemCodeMap;
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 99
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };
    if (topOrgId !== '-666') {
      queryUtils.queryCriterias = [
        new QueryCriteria('org_type', corporateStructureType.group, '=', 'and'),
        ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
        ...(inRanking ? [new QueryCriteria('in_ranking', 1, '=', 'and')] : []),
        ...(topOrgId ? [new QueryCriteria('parent_id', topOrgId.toString(), 'in', 'and', true)] : [new QueryCriteria('parent_id', '-1', 'in', 'and', true)])
      ];
    } else {
      queryUtils.queryCriterias = [
        new QueryCriteria('org_type', corporateStructureType.group, '=', 'and'),
        ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
        ...(business_line_id ? [new QueryCriteria('tt.business_line_id', business_line_id, '=', 'and')] : []),
      ];
    }

    return this.http.post<ResponseModel>(corporateAPI + '/list/table', queryUtils);
  }
  queryGroupsTrainingNew(query: string, topOrgId?: string, inRanking?, business_line_id?, deptCode?: string): Observable<ResponseModel> {
    const { corporateStructureType } = SystemCodeMap;
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 99
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };
    if (topOrgId !== '-666') {
      queryUtils.queryCriterias = [
        new QueryCriteria('org_type', corporateStructureType.group, '=', 'and'),
        ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
        ...(inRanking ? [new QueryCriteria('in_ranking', 1, '=', 'and')] : []),
        ...(topOrgId ? [new QueryCriteria('parent_id', topOrgId.toString(), 'in', 'and', true)] : [new QueryCriteria('parent_id', '-1', 'in', 'and', true)])
      ];
    } else {
      queryUtils.queryCriterias = [
        new QueryCriteria('org_type', corporateStructureType.group, '=', 'and'),
        ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
        ...(business_line_id ? [new QueryCriteria('tt.business_line_id', business_line_id, '=', 'and')] : []),
      ];
    }
    if (deptCode) {
      queryUtils['params'] = {departmentCode: deptCode};
    }
    return this.http.post<ResponseModel>(corporateAPI + '/list/tran/table', queryUtils);
  }
  queryGroupsEHS(query: string, topOrgId?: string, inRanking?, business_line_id?, time?): Observable<ResponseModel> {
    const { corporateStructureType } = SystemCodeMap;
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 99
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };
    if (topOrgId !== '-666') {
      queryUtils.queryCriterias = [
        new QueryCriteria('org_type', corporateStructureType.group, '=', 'and'),
        ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
        ...(inRanking ? [new QueryCriteria('in_ranking', 1, '=', 'and')] : []),
        ...(topOrgId ? [new QueryCriteria('parent_id', topOrgId.toString(), 'in', 'and', true)] : [new QueryCriteria('parent_id', '-1', 'in', 'and', true)])
      ];
    } else {
      queryUtils.queryCriterias = [
        new QueryCriteria('org_type', corporateStructureType.group, '=', 'and'),
        ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
        ...(business_line_id ? [new QueryCriteria('tt.business_line_id', business_line_id, '=', 'and')] : []),
      ];
    }
    if (time) {
      queryUtils['params'] = {searchTime: new Date(time).toISOString()};
    }

    return this.http.post<ResponseModel>(corporateAPI + '/list/table/history', queryUtils);
  }

  queryGroupsOnlyProject(query: string): Observable<ResponseModel> {
    const { corporateStructureType } = SystemCodeMap;
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 99
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };

    queryUtils.queryCriterias = [
      new QueryCriteria('org_type', corporateStructureType.group, '=', 'and'),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
    ];

    return this.http.post<ResponseModel>(corporateAPI + '/list/table', queryUtils);
  }

  queryTopOuidList(query: string, topOrgId?: number): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 99
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };

    queryUtils.queryCriterias = [
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
    ];

    return this.http.post<ResponseModel>(corporateAPI + '/topOuidList', queryUtils);
  }

  // 区域
  queryRegion(query: string, topOrgId?: string): Observable<ResponseModel> {
    const { corporateStructureType } = SystemCodeMap;
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 9999
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };

    queryUtils.queryCriterias = [
      new QueryCriteria('org_type', corporateStructureType.region, '=', 'and'),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(topOrgId ? [new QueryCriteria('parent_id', topOrgId.toString(), 'in', 'and', true)] : [new QueryCriteria('parent_id', '-1', 'in', 'and', true)])
    ];

    return this.http.post<ResponseModel>(corporateAPI + '/list/table', queryUtils);
  }
  queryRegionTrainingNew(query: string, topOrgId?: string, deptCode?: string): Observable<ResponseModel> {
    const { corporateStructureType } = SystemCodeMap;
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 9999
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };

    queryUtils.queryCriterias = [
      new QueryCriteria('org_type', corporateStructureType.region, '=', 'and'),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(topOrgId ? [new QueryCriteria('parent_id', topOrgId.toString(), 'in', 'and', true)] : [new QueryCriteria('parent_id', '-1', 'in', 'and', true)])
    ];
    if (deptCode) {
      queryUtils['params'] = {departmentCode: deptCode};
    }
    return this.http.post<ResponseModel>(corporateAPI + '/list/tran/table', queryUtils);
  }
  queryRegionEHS(query: string, topOrgId?: string, time?): Observable<ResponseModel> {
    const { corporateStructureType } = SystemCodeMap;
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 9999
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };

    queryUtils.queryCriterias = [
      new QueryCriteria('org_type', corporateStructureType.region, '=', 'and'),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(topOrgId ? [new QueryCriteria('parent_id', topOrgId.toString(), 'in', 'and', true)] : [new QueryCriteria('parent_id', '-1', 'in', 'and', true)])
    ];
    if (time) {
      queryUtils['params'] = {searchTime: new Date(time).toISOString()};
    }

    return this.http.post<ResponseModel>(corporateAPI + '/list/table/history', queryUtils);
  }

  // 大区
  queryDivision(query: string, topOrgId?: string): Observable<ResponseModel> {
    const { corporateStructureType } = SystemCodeMap;
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 9999
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };

    queryUtils.queryCriterias = [
      new QueryCriteria('org_type', corporateStructureType.division, '=', 'and'),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(topOrgId ? [new QueryCriteria('parent_id', topOrgId.toString(), 'in', 'and', true)] : [new QueryCriteria('parent_id', '-1', 'in', 'and', true)])
    ];
    return this.http.post<ResponseModel>(corporateAPI + '/list/table', queryUtils);
  }
  queryDivisionTrainingNew(query: string, topOrgId?: string, deptCode?: string): Observable<ResponseModel> {
    const { corporateStructureType } = SystemCodeMap;
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 9999
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };

    queryUtils.queryCriterias = [
      new QueryCriteria('org_type', corporateStructureType.division, '=', 'and'),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(topOrgId ? [new QueryCriteria('parent_id', topOrgId.toString(), 'in', 'and', true)] : [new QueryCriteria('parent_id', '-1', 'in', 'and', true)])
    ];
    if (deptCode) {
      queryUtils['params'] = {departmentCode: deptCode};
    }
    return this.http.post<ResponseModel>(corporateAPI + '/list/tran/table', queryUtils);
  }
  queryDivisionEHS(query: string, topOrgId?: string, time?): Observable<ResponseModel> {
    const { corporateStructureType } = SystemCodeMap;
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 9999
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };

    queryUtils.queryCriterias = [
      new QueryCriteria('org_type', corporateStructureType.division, '=', 'and'),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(topOrgId ? [new QueryCriteria('parent_id', topOrgId.toString(), 'in', 'and', true)] : [new QueryCriteria('parent_id', '-1', 'in', 'and', true)])
    ];
    if (time) {
      queryUtils['params'] = {searchTime: new Date(time).toISOString()};
    }
    return this.http.post<ResponseModel>(corporateAPI + '/list/table/history', queryUtils);
  }

  queryActiveCompany(query: string): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 99
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };
    queryUtils.queryCriterias = [
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...[new QueryCriteria('org_type', SystemCodeMap.corporateStructureType.company, '=', 'and')],
      ...[new QueryCriteria('status', SystemCodeMap.status.active, '=', 'and')]
    ];

    return this.http.post<ResponseModel>(corporateAPI + '/allList', queryUtils);
  }

  // 获取当前用户的默认组织架构
  getDefaultOrg(): Observable<ResponseModel> {
    return this.http.get<ResponseModel>(corporateAPI + '/list/table/default/all');
  }

  getDefaultOrgByBL(bl): Observable<ResponseModel> {
    return this.http.get<ResponseModel>(corporateAPI + '/list/table/default/' + bl);
  }

  getDefaultOrgWithoutCompany(): Observable<ResponseModel> {
    return this.http.get<ResponseModel>(corporateAPI + '/list/table/default/allWithoutCompany');
  }

  // 获取空间群组list
  getSpaceGroupList(input, valid?): Observable<ResponseModel> {
    const queryUtil: QueryUtilsModel = {
      pagingTool: {
        currentPage : 1,
        pageSize: 9999
      },
      queryCriterias: []
    };
    if (input) {
      queryUtil.queryCriterias.push(new QueryCriteria('space_group_name', input, 'like', 'and'));
    }
    if (valid) {
      queryUtil.queryCriterias.push(new QueryCriteria('status', '_SYS_A9_1', '=', 'and'));
    }
    return this.http.post<ResponseModel>(baseUrl + '/space/group/list', queryUtil);
  }

  // 获取空间类型列表
  getSpaceTypeList(input): Observable<ResponseModel> {
    const queryUtil: QueryUtilsModel = {
      pagingTool: {
        currentPage : 1,
        pageSize: 9999
      },
      queryCriterias: []
    };
    if (input) {
      queryUtil.queryCriterias = [
        new QueryCriteria('space_type_name', input, 'like', 'and'),
      ];
    }
    return this.http.post<ResponseModel>(baseUrl + '/space/type/list', queryUtil);
  }

  // 树形结构组织架构
  getTreeChildren(parentId) {
    return this.http.get<ResponseModel>(corporateAPI + '/getChildren/' + parentId);
  }

  getTreeChildrenWidthoutVInvalid(parentId) {
    return this.http.get<ResponseModel>(corporateAPI + '/getChildrenWithoutInvalid/' + parentId);
  }

}
